<template>
<div class="container">
  <div class="card">
    <div class="box">
      <div class="content">
        <h2> {{ id }} </h2>
        <h3> {{ tittle }} </h3>
        <p> {{ description }} </p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "CardPromotions",
  props: {
    id: String,
    tittle: String, 
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.FigurePromotions {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.ImgPromotions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 45px 0;
}

body .container .card {
  position: relative;
  width: 250.5px;
  height: 357.5px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: 0.5s;
}

body .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .container .card .box:hover {
  transform: translateY(-13px);
}

body .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(255, 255, 255, 0.1);
}

body .container .card .box .content h3 {
  font-size: 1.3em;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .container .card .box .content p {
  font-size: 0.9em;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

@media (min-width: 200px) and (max-width: 300px) {
  body .container {
  top: -10%;
  margin: 5px 0;
}
  body .container .card {
  width: 220.5px;
  height: 287.5px;
}
}
@media (min-width: 300px) and (max-width: 480px) {
  body  .container{
    top: -15%;
  }
  body .container .card {
  width: 260.5px;
  height: 347.5px;
}
}
@media (min-width: 480px) and (max-width: 660px) {
  body .container .card {
  width: 230.5px;
  height: 327.5px;
}
}
@media (min-width: 660px) and (max-width: 980px) {
  body .container .card {
  width: 300.5px;
  height: 307.5px;
}
}
@media (min-width: 980px) and (max-width: 1024px) {
  body .container .card {
  width: 400.5px;
  height: 307.5px;
}
}
@media (min-width: 360px) and (max-width: 360px) {
  body .container {
  margin: 24px 0;
}
  body .container .card {
  width: 260.5px;
  height: 300.5px;
}
}
@media (min-width: 320px) and (max-width: 320px) {
  body .container {
  top: 2%;
  margin: 18px 0;
}
  body .container .card {
  width: 240.5px;
  height: 270.5px;
}
}
</style>
