<template>
  <design-page class="DesignPagePromotions">
    <div class="BodyPromotionsSolutions">
      <div class="CenterContainerPromotions">
        <div class="TitleHeaderPromotions">
          <h1>TODAS NUESTRAS PROMOCIONES</h1>
        </div>
        <div class="ContainerGridPromotions">
          <div v-for="(card, index) in cardPromotions" :key="index">
            <card-promotions
              class="CardPromotions"
              :id="card.id"
              :tittle="card.tittle"
              :description="card.description"
            ></card-promotions>
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import DesignPage from "../components/DesignPage.vue";
import CardPromotions from "../components/CardPromotions.vue";
export default {
  name: "Promotions",
  components: {
    DesignPage,
    CardPromotions,
  },
  data() {
    return {
      cardPromotions: [
        {
          id: 1,
          tittle: "1 Lona Impresa Por $220",
          description: "¡Ven por tu lona! No desperdicies esta oportunidad, aprovecha y contactanos ahora."
        },
        {
          id: 2,
          tittle: "5 Rollos De Vinil Por $875",
          description: "¿Interesante? No lo dudes mas y echale un ojo a esto, contactanos lo mas pronto posible."
        },
        {
          id: 3,
          tittle: "300 Metros De Vinil Por $1040",
          description: "¡Asi es! Algo unico, ven y contactanos ahora mismo."
        },
        {
          id: 4,
          tittle: "2 Lonas Impresas Por $430",
          description: "¿La mejor opcion? Nadie te detiene ven y hagamos esto posible, contactanos de inmediato."
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.BodyPromotionsSolutions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.CenterContainerPromotions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  height: 85%;
  margin-top: 5%;
}
.TitleHeaderPromotions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-style: bold;
  font-weight: bold;
  font-size: 1em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
}
.TitleHeaderPromotions h1 {
  color: #dc9942;
}
.ContainerGridPromotions {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  align-self: center;
  grid-template-columns: auto auto auto auto;
  gap: 35px 70px;
  width: 90%;
  height: 70%;
}
@media (min-width: 200px) and (max-width: 300px){
  .BodyPromotionsSolutions{
    height: 215vh;
  }
  .CenterContainerPromotions{
    top: 30px;
    width: 90%;
    height: 85%;
    left: -9.5px;
  }
  .TitleHeaderPromotions{
    top: -3%;
    width: 100%;
  }
  .TitleHeaderPromotions h1{
    font-size: 1.3em;
  }
  .ContainerGridPromotions{
    grid-template-columns: auto;
    gap: 20px 0px ;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 300px) and (max-width: 480px){
  .BodyPromotionsSolutions{
    height: 300vh;
  }
  .CenterContainerPromotions{
    width: 90%;
    height: 85%;
  }
  .TitleHeaderPromotions{
    width: 100%;
    top: -3%;
  }
  .TitleHeaderPromotions h1{
    font-size: 1.7em;
  }
  .ContainerGridPromotions{
    grid-template-columns: auto; 
    gap: 0px 0px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 480px) and (max-width: 660px){
  .BodyPromotionsSolutions{
    height: 130vh;
  }
  .CenterContainerPromotions{
    width: 90%;
    height: 85%;
  }
  .TitleHeaderPromotions{
    width: 100%;
    top: 3%;
  }
  .TitleHeaderPromotions h1{
    font-size: 1.8em;
  }
  .ContainerGridPromotions{
    grid-template-columns: auto auto;
    gap: 0px 40px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 660px) and (max-width: 980px){
  .CenterContainerPromotions{
    width: 90%;
    height: 85%;
    top: 2%;
  }
  .TitleHeaderPromotions{
    width: 100%;
    top: 5%;
  }
  .TitleHeaderPromotions h1{
    font-size: 2.5em;
  }
  .ContainerGridPromotions{
    grid-template-columns: auto auto;
    gap: 0px 60px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 980px) and (max-width: 1024px){
  .CenterContainerPromotions{
    width: 90%;
    height: 85%;
  }
  .TitleHeaderPromotions{
    top: 3%;
    width: 100%
  }
  .TitleHeaderPromotions h1{
    font-size: 2.9em;
  }
  .ContainerGridPromotions{
    grid-template-columns: auto auto;
    gap: 0px 60px;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 360px) and (max-width: 360px) {
  .TitleHeaderPromotions h1{
    font-size: 1.6em;
  }
  .CenterContainerPromotions{
    gap: 10px 0px;
  }
}
@media (min-width: 320px) and (max-width: 320px){
  .BodyPromotionsSolutions{
    height: 245vh;
  }
  .TitleHeaderPromotions{
    top: 0%;
  }
  .TitleHeaderPromotions h1{
    font-size: 1.4em;
  }
  .CenterContainerPromotions{
    gap: 0px 0px;
  }
}

</style>
